// post
.post
	margin: 100px 0
	+animateOpenPage

	&-cover
		display: flex
		justify-content: space-between

		&-text
			padding: 50px 0
			width: 50%

		&__type
			&_gray
				display: inline-block
				border-radius: 100px
				background-color: $lightGray
				color: $black
				font-size: 18px
				padding: 5px 15px
				text-align: center

			&_color
				margin-bottom: 5px
				margin-right: 10px
				display: inline-block
				border-radius: 100px
				background-color: $mainColor
				color: $white
				font-size: 18px
				padding: 5px 15px
				text-align: center

		&__date
			display: block
			white-space: nowrap
			margin-bottom: 25px
			font-size: 24px
			color: $black

		&__title
			margin-top: 25px
			font-size: 36px

		&__description
			margin-top: 25px
			font-size: 24px

		&-auth-wrapper
			margin-top: 50px

		&-auth
			margin-bottom: 5px

		&-thumb
			border-radius: 5px
			width: 45%
			height: auto
			background-size: cover
			background-repeat: no-repeat
			background-position: center center

	&-block
		margin: 100px 0

		&__title
			font-size: 36px

		&__description
			margin-top: 50px
			font-size: 18px
			max-width: 80%

			& > h1,h2,h3,h4,h5,h6
				margin-bottom: 25px

			& > a
				color: $mainColor
				text-decoration: underline

			& > p
				font-size: 24px
				margin-bottom: 25px

				& > a
					color: $mainColor
					text-decoration: underline

			& > p > strong
				+bold

			& > ul > li
				display: block
				position: relative
				font-size: 24px
				padding: 10px 25px
				list-style: none
				&::before
					content: ''
					position: absolute
					width: 7px
					height: 7px
					border-radius: 50%
					top: 50%
					transform: translateY(-50%)
					left: 0px
					background-color: $mainColor

				&:last-child
					margin-bottom: 25px

		&-thumb
			margin-top: 50px
			border-radius: 5px
			width: 100%
			height: 400px
			background-size: cover
			background-repeat: no-repeat
			background-position: center center

// next-post
.next-post
	display: block
	padding: 100px 0
	background-color: #F0F0F0
	&:hover &-media
		transform: scale(1.1)

	&-media
		display: flex
		justify-content: space-between
		align-items: center
		+transition

	&__subtitle
		font-size: 24px

	&__title
		margin-top: 25px
		font-size: 36px

	&-arrow
		& > svg
