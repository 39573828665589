// email
.email-form
	margin: 140px 0
	+animateOpenPage

	&
		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		input:-webkit-autofill:active
			-webkit-box-shadow: 0 0 0 30px $mainColor inset !important

	&-wrapper
		border-radius: 2px
		color: $white
		display: flex
		justify-content: space-between
		align-items: center
		padding: 75px 50px
		background-color: $mainColor

		&.X1-5,&.X2
			flex-direction: column
			align-items: flex-start

	&-left
		width: 50%

		&.X1-5,&.X2
			width: 100%

	&-right
		width: 40%

		&.X1-5,&.X2
			width: 100%

	&__title
		font-size: 36px

		&.X1-5
			font-size: 54px

		&.X2
			font-size: 72px

	&__subtitle
		font-size: 24px
		margin-top: 50px

		&.X1-5
			margin-bottom: 50px
			font-size: 36px

		&.X2
			margin-bottom: 50px
			font-size: 48px

	&-input
		&__field
			background-color: transparent
			border: none
			border-bottom: 1px solid $white
			width: 100%
			padding: 10px 0
			font-size: 16px
			color: $white
			+transition

			&::placeholder
				font-size: 16px
				color: $white

			&.X1-5, &.X1-5::placeholder
				font-size: 24px

			&.X2, &.X2::placeholder
				font-size: 32px

			&_error
				color: $lightRed
				border-bottom: 1px solid $lightRed

				&::placeholder
					font-size: 16px
					color: $lightRed

	&-checkbox
		margin-top: 25px

	&__btn
		cursor: pointer
		position: absolute
		top: 50%
		right: 0
		transform: translateY(-50%)
		border: none
		padding: 0
		background-color: transparent
