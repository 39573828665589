// magazine
.magazine
	margin-top: 100px
	+animateOpenPage

	&-block-wrapper
		display: flex
		justify-content: space-between
		flex-wrap: wrap

	&-bottom
		margin-top: 100px
		display: flex
		align-items: flex-start
		justify-content: space-between

	&-filters
		width: 250px

		&__title
			font-size: 24px
			margin-bottom: 25px

		&-block
			padding-bottom: 50px
			margin-bottom: 50px
			border-bottom: 1px solid $gray
			display: flex
			flex-direction: column
			align-items: flex-start

		&-types
			display: flex
			flex-direction: column
			align-items: flex-start

		&__btn
			white-space: nowrap
			display: inline-block
			border: 1px solid $black
			background-color: transparent
			cursor: pointer
			padding: 15px 25px
			border-radius: 100px
			margin-bottom: 10px
			font-size: 15px
			+transition
			+light
			&:hover
				color: $white
				background-color: $mainColor
				border: 1px solid $mainColor

			&_active
				color: $white
				background-color: $mainColor
				border: 1px solid $mainColor
				+transition

		&-checkbox
			margin-bottom: 15px

			&:last-child
				margin-bottom: 0

	&-catalog
		width: 75%

		&-item-wrapper
			margin-bottom: 100px
			+transition

		&-middle,&-top
			display: flex
			justify-content: space-between
			flex-wrap: wrap

		&-bottom
			display: flex
			justify-content: center

		&-top
			align-items: center
			padding-bottom: 25px
			border-bottom: 1px solid $gray
			margin-bottom: 50px

		&__title
			font-size: 36px

		&__number
			font-size: 18px
			& > span
				+bold

	&-block
		width: 49%
		display: flex
		flex-direction: column
		justify-content: space-between
		margin-bottom: 50px
		padding-bottom: 50px
		border-bottom: 1px solid $gray

		&:hover &-img
			transform: scale(1.1)

		&-thumb
			position: relative
			overflow: hidden
			height: 300px
			border-radius: 2px

		&-img
			background-size: cover
			background-repeat: no-repeat
			background-position: center center
			height: 100%
			+transition

		&-types
			position: absolute
			top: 0
			width: 100%
			display: flex
			justify-content: space-between
			align-items: center
			padding: 20px

		&__types
			&_gray
				text-align: center
				display: inline-block
				border-radius: 100px
				color: $black
				background-color: $lightGray
				padding: 5px 15px
				font-size: 17px
				text-align: center

			&_color
				text-align: center
				display: inline-block
				border-radius: 100px
				background-color: $mainColor
				color: $white
				padding: 5px 15px
				font-size: 17px
				text-align: center

		&__date
			font-size: 18px
			display: block
			margin-top: 25px
			color: $black

		&__title
			font-size: 32px
			margin-top: 25px

		&__description
			margin-top: 25px
			font-size: 24px

		&-bottom
			flex-direction: column

		&-auth-wrapper
			margin-top: 50px

		&-auth
			margin-bottom: 10px

	&-block_big
		width: 100%
		display: flex
		flex-direction: column
		justify-content: space-between
		margin-bottom: 50px
		padding-bottom: 50px
		border-bottom: 1px solid $gray
		&:hover &-img
			transform: scale(1.1)

		&-thumb
			position: relative
			overflow: hidden
			height: 400px

		&-img
			border-radius: 2px
			background-size: cover
			background-repeat: no-repeat
			background-position: center center
			height: 100%
			+transition

		&-types
			position: absolute
			top: 0
			width: 100%
			display: flex
			justify-content: space-between
			align-items: center
			padding: 20px

		&__types
			&_gray
				display: inline-block
				border-radius: 100px
				color: $black
				background-color: $lightGray
				padding: 10px 15px
				font-size: 18px
				text-align: center

			&_color
				display: inline-block
				border-radius: 100px
				background-color: $mainColor
				color: $white
				padding: 10px 15px
				font-size: 18px
				text-align: center

		&__date
			font-size: 24px
			display: block
			margin-top: 25px
			color: $black

		&__title
			font-size: 36px
			margin-top: 25px

		&__description
			margin-top: 25px
			font-size: 24px

		&-auth-wrapper
			margin-top: 50px

		&-auth
			margin-bottom: 10px

			&__name
				font-size: 20px

			&__img
				width: 65px
				height: 65px
				border-radius: 50%
				background-size: cover
				background-repeat: no-repeat
				background-position: center center

	&-btn-wrapper
		margin-top: 50px
		display: flex
		justify-content: center