// error
.error
	margin-top: 100px
	margin-bottom: 150px
	+animateOpenPage

	&__title
		font-size: 72px
		max-width: 1100px
		margin-bottom: 25px

	&__subtitle
		max-width: 600px
		font-size: 24px

		& > a
			color: $mainColor
			text-decoration: underline
			
	&__btn
		margin-top: 50px
