// shop
.shop
	margin-top: 100px
	+animateOpenPage

	&-input
		width: 400px

	&-block-top
		display: flex
		justify-content: space-between
		align-items: center
		margin-bottom: 50px

	&-filters
		display: flex
		align-items: flex-start
		justify-content: space-between
		flex-wrap: wrap
		margin-bottom: 50px
		padding-top: 50px
		border-top: 1px solid $gray

		&-clear__btn
			margin-right: 35px

		&-block-wrapper
			width: 25%
			border-right: 1px solid $gray
			&:first-child
				border-left: 1px solid $gray

		&-block
			padding: 0 25px
			height: 200px
			overflow-y: auto

			&::-webkit-scrollbar
				width: 4px
				height: 7px
				background-color: #F0F0F0

			&::-webkit-scrollbar-thumb
				background-color: #E2E2E2
				border-radius: 3px

			&::-webkit-scrollbar-thumb:hover
				background-color: #E2E2E2

			&::-ms-scrollbar
				width: 4px
				height: 8px
				background-color: #F0F0F0

			&::-ms-scrollbar-thumb
				background-color: #E2E2E2
				border-radius: 3px

			&::-ms-scrollbar-thumb:hover
				background-color: #E2E2E2

		&__title
			font-size: 24px
			margin-bottom: 25px

		&-checkbox
			margin-bottom: 15px

			&:last-child
				margin-bottom: 0

		&-input
			display: flex
			justify-content: space-between
			align-items: center
			margin-bottom: 25px

			&-clear
				z-index: 2
				position: absolute
				top: 50%
				transform: translateY(-50%)
				right: 12px
				background-color: $white
				cursor: pointer
				+transition

				&__hidden
					opacity: 0
					z-index: -1

		&__input
			width: 49%

		&-range
			margin-top: 25px

			&-span
				margin-top: 25px
				display: flex
				justify-content: space-between

			&__span
				font-size: 14px
				color: $gray

	&-catalog
		margin-bottom: 50px

	&-block
		display: flex
		align-items: center
		justify-content: space-between
		padding: 50px 0
		border-bottom: 1px solid $gray
		+transition
		+animateOpenPage

		&:first-child
			border-top: 1px solid $gray

		&-left
			width: 50%
			display: flex
			align-items: center

		&-right
			width: 45%
			display: flex
			justify-content: space-between
			align-items: center

		&-thumb
			position: relative

		&__img
			width: 150px
			height: 150px
			border-radius: 50%
			background-size: cover
			background-repeat: no-repeat
			background-position: center center

		&__sale
			display: flex
			align-items: center
			justify-content: center
			border-radius: 50%
			position: absolute
			top: 0
			left: 0
			color: $white
			width: 50px
			height: 50px
			background-color: $mainColor
			font-size: 14px

		&-text
			margin-left: 50px

		&-type-wrapper
			display: flex
			flex-wrap: wrap
			align-items: center

		&__type
			&_gray
				white-space: nowrap
				margin: 0 2.5px
				margin-bottom: 5px
				display: inline-block
				border-radius: 100px
				background-color: $lightGray
				color: $black
				font-size: 16px
				padding: 5px 15px
				text-align: center

			&_color
				white-space: nowrap
				margin-right: 2.5px
				margin-bottom: 5px
				display: inline-block
				border-radius: 100px
				background-color: $mainColor
				color: $white
				font-size: 16px
				padding: 5px 15px
				text-align: center

		&__title
			font-size: 32px
			margin: 10px 0

			+transition

			&:hover
				color: $mainColor

		&__time
			font-size: 18px

		&__awo
			font-size: 18px
			margin-left: 15px
			color: #A9A9A9

			&_color
				font-size: 18px
				margin-left: 15px
				color: $mainColor

		&__subprice
			font-size: 24px
			color: $gray
			text-decoration: line-through

		&__price
			font-size: 32px
			margin-top: 5px

			+transition

			&:hover
				color: $mainColor

		&-btn
			width: auto

			position: relative
			display: flex
			flex-direction: column
			align-items: center

			&-annotation
				width: 500px
				display: flex
				flex-direction: column
				align-items: flex-end

				position: absolute
				right: -15px
				top: -15px
				z-index: 2
				+transition

				&__title
					display: flex

					justify-content: center
					align-items: center

					font-size: 21px
					color: $mainColor
					background-color: $white

					width: 40px
					height: 40px

					border-radius: 50%
					cursor: pointer
					box-shadow: 0 0 50px rgba(0, 0, 0, 0.1)

				&__text
					font-size: 17px
					margin-top: 20px

					background-color: $white

					padding: 20px

					border-radius: 5px
					cursor: pointer
					box-shadow: 0 0 50px rgba(0, 0, 0, 0.1)
					opacity: 0
					+transition

				&:hover &__text
					opacity: 1
					margin-top: 15px

		&__link
			font-size: 17px
			color: $mainColor
			margin-top: 15px

	&-btn-wrapper
		margin-top: 50px
		margin-bottom: 100px
		display: flex
		justify-content: center

	&-bottom
		display: flex
		justify-content: center
		margin-bottom: 50px

// shop-page
.shop-page
	// form
	&-form
		width: 100%
		margin-top: 25px

		&-input
			margin-bottom: 15px

		&__link
			width: 100%

		&__btn
			width: 100%
			margin-bottom: 15px

		&-thank
			padding: 30px
			border-radius: 15px

			margin: 50px 0

			background-color: $lightGray

			+animateOpenPage

			&__title
				font-size: 32px
				margin-bottom: 25px

			&__description
				font-size: 24px

	// main1
	&-main1
		margin: 75px 0
		&-wrapper
			position: relative
		&__subtitle
			font-size: 24px
			margin-bottom: 25px
			&.X1-5
				font-size: 36px
			&.X2
				font-size: 48px
		&__title
			font-size: 54px
			max-width: 1200px
			& > span
				color: $mainColor
			&.X1-5
				max-width: 100%
				font-size: 108px
			&.X2
				max-width: 100%
				font-size: 144px
		&__description
			font-size: 24px
			margin-top: 25px
			max-width: 800px
			&.X1-5, &.X2
				max-width: 100%
			&.X1-5
				font-size: 36px
			&.X2
				font-size: 48px
		&__btn
			margin-top: 50px
			&.X1-5
				font-size: 24px
			&.X2
				font-size: 32px

		&-form
			width: 50%

	// main1-image
	&-main1-image
		margin: 75px 0
		&-wrapper
			position: relative
			display: flex
			align-items: center
			justify-content: space-between
		&-text
			max-width: 50%
			&__subtitle
				font-size: 21px
				margin-bottom: 25px
				&.X1-5
					font-size: 36px
				&.X2
					font-size: 48px
			&__title
				font-size: 46px
				& > span
					color: $mainColor
				&.X1-5
					max-width: 100%
					font-size: 108px
				&.X2
					max-width: 100%
					font-size: 144px
			&__description
				font-size: 21px
				margin-top: 25px
				max-width: 800px
				&.X1-5, &.X2
					max-width: 100%
				&.X1-5
					font-size: 36px
				&.X2
					font-size: 48px
			&__btn
				margin-top: 50px
				&.X1-5
					font-size: 24px
				&.X2
					font-size: 32px
		&-cover
			width: 40%
			&__image
				user-select: none
				width: 100%
				border-radius: 15px
	// main2
	&-main2
		margin: 75px 0
		&-wrapper
			position: relative
		&__subtitle
			font-size: 24px
			margin-bottom: 25px
			&.X1-5
				font-size: 36px
			&.X2
				font-size: 48px
		&__title
			font-size: 54px
			max-width: 1200px
			& > span
				color: $mainColor
			&.X1-5
				max-width: 100%
				font-size: 108px
			&.X2
				max-width: 100%
				font-size: 144px
		&__description
			font-size: 24px
			margin-top: 25px
			max-width: 800px
			&.X1-5, &.X2
				max-width: 100%
			&.X1-5
				font-size: 36px
			&.X2
				font-size: 48px
		&__btn
			margin-top: 50px
			&.X1-5
				font-size: 18px
			&.X2
				font-size: 24px
		&__date
			display: block
			font-size: 24px
			& > b
				+bold
			&.X1-5
				font-size: 36px
			&.X2
				font-size: 48px
			&-range
				display: inline-block
				margin-bottom: 15px
				font-size: 24px
				& > b
					+bold
				&.X1-5
					font-size: 36px
				&.X2
					font-size: 48px
		&-date
			margin-top: 50px
			display: flex
			&-left
				margin-right: 30px
			&-right
				display: flex
				flex-direction: column
		&__btn
			width: 50%
			&.X1-5
				font-size: 24px
			&.X2
				font-size: 32px
		&-form
			width: 50%
			margin-top: 50px
	// main2-image
	&-main2-image
		margin: 75px 0
		&-wrapper
			position: relative
			display: flex
			align-items: center
			justify-content: space-between
		&-text
			width: 55%
			&__subtitle
				font-size: 24px
				margin-bottom: 25px
			&__title
				font-size: 54px
				max-width: 1200px
				& > span
					color: $mainColor
			&__description
				font-size: 24px
				margin-top: 25px
				max-width: 800px
			&__btn
				margin-top: 50px
			&__date
				display: block
				font-size: 24px
				& > b
					+bold
				&-range
					display: inline-block
					margin-bottom: 15px
					font-size: 24px
					& > b
						+bold
			&-date
				margin-top: 50px
				display: flex
				&-left
					margin-right: 30px
				&-right
					display: flex
					flex-direction: column
			&__btn
				width: 100%
			&-form
				width: 100%
				margin-top: 50px
		&-cover
			width: 40%
			&__image
				user-select: none
				width: 100%
				border-radius: 15px
	// services
	&-services
		margin-bottom: 50px
	// slider-text
	&-slider-text
		margin: 100px 0
		&-tabs
			&-item
				padding-right: 100px
				&__title
					text-align: left
					white-space: pre-wrap
					font-size: 42px
					color: #DDDDFD
					text-decoration: underline
					cursor: pointer
					+transition
					&.active
						color: $mainColor
					&.X1-5
						font-size: 58px
					&.X2
						font-size: 72px
		&-arrow
			margin-top: 50px
		&-list
			width: 100%
			margin-top: 50px
			padding-top: 50px
			position: absolute
			&-wrapper
				position: relative
			&.active
				animation-name: shopPageTabs
				animation-duration: .4s
			&-ul
				display: flex
				justify-content: space-between
				flex-wrap: wrap
			&__li
				padding: 0 50px
				margin-bottom: 50px
				width: 49%
				position: relative
				font-size: 24px
				list-style: none
				padding-bottom: 50px
				opacity: 1
				&::before
					content: ""
					position: absolute
					height: 2px
					width: 20px
					top: 15px
					left: 0px
					background-color: $mainColor
				&.X1-5, &.X2
					width: 100%
				&.X1-5
					font-size: 36px
				&.X2
					font-size: 48px
		&-circle1
			width: 450px
			height: 450px
		&-circle2
			position: absolute
			bottom: -100px
			right: 100px
			width: 450px
			height: 450px
		&__btn
			&.X1-5
				font-size: 24px
			&.X2
				font-size: 32px
	// composition-product
	&-composition-product
		margin: 100px 0

		&-modules
			&-description
				margin: 25px 0

			&__description
				font-size: 26px
				max-width: 900px

				& ul, ol
					margin-left: 15px

				&.X1-5, &.X2
					max-width: 100%

				&.X1-5
					font-size: 32px

				&.X2
					font-size: 42px

			&-items-wrapper
				display: flex
				align-items: center

			&-item
				padding-right: 100px

				&__title
					white-space: pre-wrap
					font-size: 42px
					color: #DDDDFD
					text-decoration: underline
					cursor: pointer
					+transition

					&.X1-5
						font-size: 58px

					&.X2
						font-size: 72px

					&.active
						color: $mainColor

		&-list
			width: 100%
			position: absolute

			padding-left: 25px

			border-left: 3px solid $mainColor

			&-wrapper
				position: relative

			&.active
				animation-name: shopPageTabs
				animation-duration: .4s

			&-item
				align-items: center
				display: flex
				padding-bottom: 25px
				margin-bottom: 25px

				&:last-child
					margin-bottom: 0

				&.X1-5, &.X2
					flex-direction: column

				&__title
					font-size: 36px
					color: $mainColor
					margin-right: 50px

					&.X1-5
						font-size: 54px

					&.X2
						font-size: 72px

				&__description
					font-size: 21px
					max-width: 900px

					&.X1-5, &.X2
						padding-top: 25px
						max-width: 100%

					&.X1-5
						font-size: 32px

					&.X2
						font-size: 42px

		&-block
			display: flex
			justify-content: space-between

			padding: 25px
			border-radius: 15px
			background-color: $lightGray

			&-text
				width: 55%

				&__title
					font-size: 32px
					margin-bottom: 15px

				&__description
					font-size: 24px

				&__price
					margin-top: 25px
					font-size: 32px
					color: $mainColor

			&-form
				width: 40%

				&-input
					margin-bottom: 15px

				&__btn
					width: 100%

	// feedback-slider
	&-feedback-slider
		margin: 100px 0
		&__title
			margin-bottom: 50px
		&-item
			width: 250px
			height: 300px
			margin: 10px 0
			background-size: contain
			background-repeat: no-repeat
			background-position: center center
			cursor: zoom-in
			+boxShadow
			&-wrapper
				margin: 0 25px
		&-arrow
			margin-top: 50px
			display: flex
			justify-content: center

	&-feedback-slider-video
		&-item
			display: flex
			align-items: center
			justify-content: center

			width: 100%
			height: 250px
			margin: 10px 0

			background-size: contain
			background-repeat: no-repeat
			background-position: center center

			cursor: zoom-in
			background: $white

			&-iframe
				display: flex
				align-items: center
				justify-content: center

				width: 100% !important
				height: 100% !important

			& > iframe
				width: 100% !important
				height: 100% !important

			&-bg
				width: 100%
				height: 100%

				background-position: center center
				background-size: cover
				background-repeat: no-repeat

	// &-modal
	&-modal
		&-wrapper
			display: flex
			justify-content: center
			align-items: flex-start
			z-index: -1000
			opacity: 0
			width: 100%
			height: 100%
			position: fixed
			top: 0
			left: 0
			background-color: rgba(0,0,0, 0.4)
			overflow-y: auto
			transition: opacity .4s ease, z-index 1.1s ease

			&_active
				animation: .4s activeModalWrapper
				z-index: 1000
				opacity: 1
				transition: none

		&-content
			position: relative
			display: inline-flex
			justify-content: center
			align-items: center
			margin: 50px 1em
			padding: 50px 0
			border-radius: 2px
			min-height: 60%
			height: auto
			min-width: 40%
			max-width: 90%
			background-color: $white

			&_active
				animation: .4s activeModalContent

		&-close
			cursor: pointer
			position: absolute
			right: 30px
			top: 30px
			stroke: $black

		&-text
			width: 75%
			display: flex
			flex-direction: column
			align-items: center
			justify-content: center

		&__title
			font-size: 28px
			margin-bottom: 50px

		&-btn
			width: 100%
			display: flex
			flex-direction: column
			align-items: center
			justify-content: center

		&__btn
			width: 100%
			margin-bottom: 10px

		&-form
			margin-bottom: 50px

			&__btn
				width: 100%
				margin: 15px 0

				&.X1-5
					font-size: 24px

				&.X2
					font-size: 32px

	// &-awo-form
	&-awo-form
		margin: 25px 0

		&__title
			font-size: 36px

			&.X1-5
				font-size: 54px

			&.X2
				font-size: 72px

		&-middle
			margin: 35px 0

		&-block-wrapper
			height: 100%
			width: 75%
			display: flex
			justify-content: space-between
			align-items: flex-start

			&.X1-5,&.X2
				width: 100%

		&-input-wrapper
			width: 49%

		&-input
			position: relative
			height: 100%
			width: 100%

			&__field
				width: 100%
				height: 100%

		&__btn
			width: 49%

			&.X1-5
				font-size: 24px

			&.X2
				font-size: 32px

	// &-faq
	&-faq
		margin: 100px 0

		& ul, ol
			margin-left: 15px

		&__title
			font-size: 42px
			margin-bottom: 25px

		&-item
			cursor: pointer
			padding: 25px 25px 0 25px
			border-radius: 15px
			background-color: $lightGray

			overflow: hidden

			margin-bottom: 15px

			&-top
				display: flex
				justify-content: space-between
				align-items: center

				padding-bottom: 25px

				&__title
					font-size: 32px

				&-arrow
					+transition

					&.rotate
						transform: rotate(180deg)

					& > svg
						width: 25px

			&-bottom
				+transition

				&__description
					font-size: 21px

	// &-comparison
	&-comparison
		margin: 100px 0

		&__title
			font-size: 42px
			margin-bottom: 25px

		&-blocks
			display: flex
			justify-content: space-between

		&-block
			width: 49%

			padding: 25px
			border-radius: 15px
			background-color: $lightGray

			&.main-block
				background-color: $mainColor
				color: $white

			&__title
				font-size: 32px
				margin-bottom: 25px

			&-list
				&__item
					font-size: 18px
					margin-bottom: 15px

					&:last-child
						margin-bottom: 0

	// &-content
	&-content
		margin: 75px 0

		&-wrapper
			position: relative
			display: flex
			justify-content: space-between
			align-items: flex-start

		&-text
			max-width: 50%

			&__subtitle
				display: block
				font-size: 24px
				margin-bottom: 15px

			&__title
				font-size: 46px

			&__description
				font-size: 21px
				margin-top: 25px
				max-width: 800px

		&-cover
			width: 40%

			&__image
				user-select: none
				width: 100%
				border-radius: 15px

	// &-video
	&-video
		margin: 75px 0

		&__title
			font-size: 42px
			margin-bottom: 25px

		&-block
			width: 100%
			margin-bottom: 15px

			border-radius: 15px

			& iframe
				width: 100%
				height: 600px

				border-radius: 15px

	// &-demo
	&-demo
		margin: 100px 0

		&-wrapper
			position: relative
			display: flex
			align-items: center
			justify-content: space-between

		&-text
			width: 48%

			&__title
				font-size: 54px
				max-width: 1200px

				& > span
					color: $mainColor

			&__description
				font-size: 24px
				margin-top: 25px
				max-width: 800px

			&__btn
				margin-top: 50px
				width: 100%

			&-form
				width: 100%
				margin-top: 50px

				&-input
					margin-bottom: 15px

				&__btn
					width: 100%

			&-thank
				padding: 30px
				border-radius: 15px

				background-color: $lightGray

				margin: 25px 0

				&__title
					font-size: 54px
					max-width: 1200px

					& > span
						color: $mainColor

				&__description
					font-size: 24px
					margin-top: 25px
					max-width: 800px

		&-cover
			width: 40%

			&__image
				user-select: none
				width: 100%
				border-radius: 15px

	// &-programm
	&-programm
		margin: 100px 0

		& ul, ol
			margin-left: 15px

		&__title
			font-size: 42px
			margin-bottom: 25px

		&__description
			width: 75%
			font-size: 21px
			margin-bottom: 50px

		&-items-wrapper
			background-color: $lightGray
			border-radius: 15px

		&-item
			cursor: pointer
			padding: 25px 25px 0 25px

			overflow: hidden

			border-bottom: 1px solid $gray

			&:last-child
				border: none

			&-top
				display: flex
				justify-content: space-between
				align-items: center

				padding-bottom: 25px

				&-text
					&__subtitle
						font-size: 21px
						margin-bottom: 10px

					&__title
						font-size: 32px

				&-arrow
					+transition

					&.rotate
						transform: rotate(180deg)

					& > svg
						width: 25px

			&-bottom
				+transition

				&__description
					font-size: 21px

	// &-fixed-form
	&-fixed-form
		margin: 75px 0

		&-wrapper
			width: 60%

		&__title
			font-size: 42px
			margin-bottom: 25px

		&__description
			font-size: 24px

	// &-text-form
	&-text-form
		margin: 75px 0

		&-wrapper
			display: flex
			justify-content: space-between
			align-items: flex-start

			padding: 25px
			border-radius: 15px
			background-color: $lightGray

		&-text
			width: 50%

			&__title
				font-size: 42px
				margin-bottom: 25px

			&__description
				font-size: 24px

				& ul
					padding-left: 25px

		&-form
			width: 45%
