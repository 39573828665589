// feedback
.feedback
	margin: 100px 0
	+animateOpenPage

	&-top
		display: flex
		justify-content: space-between
		align-items: center

		margin-bottom: 50px

		padding-bottom: 50px
		border-bottom: 1px solid $gray

		&__title
			font-size: 48px

			&.X1-5
				font-size: 72px

			&.X2
				font-size: 96px

	&-filters
		display: flex
		align-items: flex-start
		justify-content: space-between
		flex-wrap: wrap
		margin-bottom: 50px

		padding-bottom: 50px
		border-bottom: 1px solid $gray

		&-clear__btn
			margin-right: 35px

		&-block-wrapper
			width: 50%
			border-right: 1px solid $gray
			&:first-child
				border-left: 1px solid $gray

		&-block
			padding: 0 25px
			height: 200px
			overflow-y: auto

			&::-webkit-scrollbar
				width: 4px
				height: 7px
				background-color: #F0F0F0

			&::-webkit-scrollbar-thumb
				background-color: #E2E2E2
				border-radius: 3px

			&::-webkit-scrollbar-thumb:hover
				background-color: #E2E2E2

			&::-ms-scrollbar
				width: 4px
				height: 8px
				background-color: #F0F0F0

			&::-ms-scrollbar-thumb
				background-color: #E2E2E2
				border-radius: 3px

			&::-ms-scrollbar-thumb:hover
				background-color: #E2E2E2

		&__title
			font-size: 24px
			margin-bottom: 25px

		&-checkbox
			margin-bottom: 15px

			&:last-child
				margin-bottom: 0

	&-block-wrapper
		display: flex
		flex-wrap: wrap
		+animateOpenPage

	&-block
		width: 33%
		padding: 0 15px
		margin-bottom: 50px
		+animateOpenPage

		&.X1-5,	&.X2
			width: 100%

		&-cover
			cursor: pointer
			height: 250px
			background-size: cover
			background-repeat: no-repeat
			background-position: center center
			border-radius: 15px

			&.X1-5,	&.X2
				height: 500px

		&-text
			margin-top: 25px

			&__name
				font-size: 24px

				&.X1-5
					font-size: 36px

				&.X2
					font-size: 48px

			&__course
				margin-top: 10px
				font-size: 21px
				color: $gray

				& > a
					color: $gray
					text-decoration: underline

	&-modal
		overflow-y: auto

		z-index: 2

		display: flex
		align-items: flex-start
		justify-content: center

		position: fixed
		top: 0
		left: 0

		width: 100%
		height: 100%

		background-color: rgba(0,0,0, 0.4)

		+activeModalWrapper

		&.close
			+hiddenModalWrapper

		&-content
			padding: 50px
			margin: 50px 0

			width: 80%
			min-height: 100%

			background-color: $white
			border-radius: 5px
			+activeModalContent

			&.close
				+hiddenModalContent

			&-text
				width: 85%

				&__name
					font-size: 32px

				&__course
					margin-bottom: 35px

					margin-top: 10px
					font-size: 24px
					color: $gray

					& > a
						color: $gray
						text-decoration: underline

				&__description
					font-size: 24px

			&-video
				margin-top: 50px
				width: 70%

				&-wrapper
					position: relative
					padding-bottom: 56.25% // 16:9
					height: 0

					overflow: hidden
					border-radius: 5px

					& iframe
						position: absolute
						top: 0
						left: 0
						width: 100%
						height: 100%

		&-close
			cursor: pointer
			position: absolute

			right: 30px
			top: 30px

			width: 50px
			height: 50px

			display: flex
			justify-content: center
			align-items: center

			border-radius: 50%
			stroke: $black
			background-color: #fff

	&-bottom
		display: flex
		justify-content: center
		margin-bottom: 50px