*
	margin: 0
	padding: 0
	outline: none
	box-sizing: border-box

	&::selection
		background-color: #B4D5FE

body
	font-size: 15px
	color: $black
	overflow-y: scroll
	+light

p
	line-height: 1.5em

// link
a
	text-decoration: none
	color: $black

// form
form
	width: 100%

// input
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
	-webkit-box-shadow: 0 0 0 30px white inset !important

input
	background-color: transparent
	+light

.input
	position: relative

	&__field
		position: relative
		z-index: 2
		border-radius: 2px
		width: 100%
		border: 1px solid $gray
		background-color: transparent
		padding-top: 20px
		padding-bottom: 20px
		padding-left: 20px
		font-size: 18px
		+transition

		&:focus ~ label,
		&:valid ~ label
			opacity: 0

		&__error
			border: 1px solid $red

	&__label
		z-index: 0
		position: absolute
		top: 50%
		transform: translateY(-50%)
		left: 20px
		display: flex
		align-items: center
		color: $gray
		font-size: 18px
		+transition

		& > svg
			margin-right: 15px

		&__error
			color: #FD5754

	&-eye
		position: absolute
		right: 15px
		top: 50%
		transform: translateY(-45%)
		z-index: 2
		user-select: none
		cursor: pointer
		width: 20px
		height: 20px

	&__label__error_bottom
		position: relative
		display: block
		margin-top: 15px
		color: $lightRed
		font-size: 16px
		animation: inputLabelTop .2s

.input
	&_small
		position: relative

	&__field_small
		position: relative
		border-radius: 2px
		width: 100%
		z-index: 2
		border: 1px solid $gray
		background-color: transparent
		padding-top: 8px
		padding-bottom: 8px
		padding-left: 15px
		font-size: 14px
		+transition

		&:focus ~ label,
		&:valid ~ label
			opacity: 0

	&__label_small
		user-select: none
		z-index: 0
		position: absolute
		top: 50%
		transform: translateY(-50%)
		left: 15px
		display: flex
		align-items: center
		color: $gray
		font-size: 14px
		+transition

		& > svg
			margin-right: 15px

.input
	&-file
		opacity: 0
		position: absolute
		z-index: -100

		&__label
			cursor: pointer

// select
.select
	position: relative

	&__select
		border-radius: 2px
		width: 100%
		border: 1px solid $gray
		background-color: transparent
		padding-top: 15px
		padding-bottom: 15px
		padding-left: 20px
		font-size: 18px
		appearance: none
		+light
		+transition

		&__error
			border: 1px solid $red
			color: $red

	&__option
		color: $black

	&:after
		content: ""
		position: absolute
		right: 15px
		top: 50%
		transform: translateY(-50%)
		z-index: 1
		width: 13px
		height: 13px
		pointer-events: none
		box-sizing: border-box
		background-position: center center
		background-size: contain
		background-repeat: no-repeat
		background-image: url("data:image/svg+xml,%3Csvg width='62' height='38' viewBox='0 0 62 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M31 38L0.68911 0.5L61.3109 0.5L31 38Z' fill='%23CCCCCC'/%3E%3C/svg%3E")

// range
.rc-slider
	position: relative
	height: 14px
	padding: 5px 0
	width: 100%
	border-radius: 6px
	touch-action: none
	box-sizing: border-box
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0)

	&-track
		position: absolute
		left: 0
		width: 100%
		height: 3px
		background-color: $mainColor
		border-radius: 100px

	&-handle
		appearance: none
		background-color: $white
		border: 1px solid $gray
		height: 25px
		width: 25px
		position: absolute
		cursor: pointer
		cursor: -webkit-grab
		margin-top: -10px
		cursor: grab
		border-radius: 50%
		touch-action: pan-x

	&-rail
		position: absolute
		width: 100%
		background-color: #e9e9e9
		height: 3px
		border-radius: 6px

	&-step
		position: absolute
		width: 100%
		height: 3px
		background: transparent

	&-slider
		width: 100%
		height: 3px
		background-color: $mainColor
		border-radius: 100px

// container
.container
	position: relative
	width: 90%
	margin: 0 auto

	max-width: 1200px

// wrapper
.wrapper
	overflow-x: hidden

// circle
.circle
	&-wrapper
		position: relative
		z-index: -1

	&-regular
		z-index: -1
		border-radius: 50%
		border: 1px solid $gray

	&-bold
		z-index: -1
		border-radius: 50%
		background-color: $lightMainColor

// title
h1,h2,h3,h4,h5,h6
	line-height: 1.3em
	+bold

	& > span
		color: $mainColor

.title
	font-size: 48px
	margin-bottom: 80px

// btn
.btn-regular_color
	cursor: pointer
	line-height: 1.5em
	user-select: none
	text-align: center
	display: inline-block
	border-radius: 2px
	border: 1px solid $mainColor
	background-color: transparent
	color: $mainColor
	padding: 20px 40px
	font-size: 15px
	+light
	+transition
	&:hover
		background-color: $mainColor
		color: $white

.btn-bold_color
	cursor: pointer
	line-height: 1.5em
	user-select: none
	text-align: center
	display: inline-block
	border-radius: 2px
	border: 1px solid $mainColor
	font-size: 15px
	background-color: $mainColor
	color: $white
	padding: 20px 40px
	+transition
	+light
	&:hover
		background-color: #1A17EA

	&_disabled
		pointer-events: none
		border: 3px solid $lightGray
		background-color: $lightGray
		color: $gray

.btn-regular_gray
	cursor: pointer
	line-height: 1.5em
	user-select: none
	text-align: center
	display: inline-block
	border-radius: 2px
	border: 1px solid $lightGray
	background-color: transparent
	color: $black
	padding: 20px 40px
	font-size: 15px
	+light
	+transition
	&:hover
		border: 1px solid $gray

.btn-bold_gray
	cursor: pointer
	line-height: 1.5em
	user-select: none
	text-align: center
	display: inline-block
	border-radius: 2px
	border: 1px solid $lightGray
	background-color: $lightGray
	color: $black
	padding: 20px 40px
	font-size: 15px
	+light
	+transition
	&:hover
		background-color: #E5E5E5

.btn-bold_color_icon
	cursor: pointer
	line-height: 1.5em
	user-select: none
	text-align: center
	display: inline-flex
	justify-content: center
	align-items: center
	border-radius: 2px
	border: 1px solid $mainColor
	background-color: $mainColor
	color: $white
	padding: 20px 40px
	font-size: 15px
	+light
	+transition

	& > svg
		margin-right: 15px
		+transition

	&.white > svg
		fill: $white

	&.border
		border: 1px solid $mainColor

.btn-regular_color_icon
	cursor: pointer
	line-height: 1.5em
	user-select: none
	text-align: center
	display: inline-flex
	justify-content: center
	align-items: center
	border-radius: 2px
	border: 1px solid $mainColor
	background-color: transparent
	color: $mainColor
	padding: 20px 40px
	font-size: 15px
	+light
	+transition

	& > svg
		fill: $mainColor
		stroke: $mainColor
		margin-right: 15px
		+transition

.btn-clear-regular_color_icon
	cursor: pointer
	line-height: 1.5em
	user-select: none
	text-align: center
	display: inline-flex
	justify-content: center
	align-items: center
	border: none
	background-color: transparent
	color: $mainColor
	font-size: 15px
	+light
	+transition

	& > svg
		fill: $mainColor
		stroke: $mainColor
		margin-right: 15px

.btn-regular_gray_icon
	cursor: pointer
	line-height: 1.5em
	user-select: none
	text-align: center
	display: inline-flex
	justify-content: center
	align-items: center
	border-radius: 2px
	border: 1px solid $lightGray
	background-color: transparent
	color: $gray
	padding: 20px 40px
	font-size: 15px
	+light
	+transition

	&:hover
		color: $mainColor
		border: 1px solid $mainColor

	&:hover > svg
		fill: $mainColor
		stroke: $mainColor

	& > svg
		fill: $gray
		stroke: $gray
		margin-right: 15px
		+transition

.btn-bold_gray_icon
	cursor: pointer
	line-height: 1.5em
	user-select: none
	text-align: center
	display: inline-flex
	justify-content: center
	align-items: center
	border-radius: 2px
	border: none
	background-color: $lightGray
	color: $black
	font-size: 15px
	padding: 20px 40px
	font-size: 15px
	+light
	+transition

	& > svg
		fill: $gray
		stroke: $gray
		margin-right: 15px

	&.rotate > svg
		+transition

	&:hover > svg
		transform: rotate(180deg)

// checkbox
.checkbox
	position: absolute
	z-index: -1
	opacity: 0

	&-wrapper
		display: flex
		align-items: center

	&+label
		cursor: pointer
		display: inline-flex
		align-items: center
		user-select: none
		font-size: 16px

	&+label::before
		content: ''
		display: inline-block
		min-width: 20px
		height: 20px
		border: 1px solid $black
		border-radius: 2px
		background-repeat: no-repeat
		background-position: center center
		background-size: 45% 35%
		margin-right: 15px

	&:checked+label::before
		background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='0.353553' y1='3.56313' x2='4.43689' y2='7.64647' stroke='%233A38DC'/%3E%3Cline x1='3.72982' y1='7.64645' x2='10.7298' y2='0.646445' stroke='%233A38DC'/%3E%3C/svg%3E")
		border: 1px solid $mainColor

.checkbox_error
	position: absolute
	z-index: -1
	opacity: 0

	&-wrapper
		display: flex
		align-items: center

	&+label
		cursor: pointer
		display: inline-flex
		align-items: center
		user-select: none
		font-size: 16px

	&:checked+label::before
		background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='0.353553' y1='3.56313' x2='4.43689' y2='7.64647' stroke='%233A38DC'/%3E%3Cline x1='3.72982' y1='7.64645' x2='10.7298' y2='0.646445' stroke='%233A38DC'/%3E%3C/svg%3E")
		border: 1px solid $mainColor

	&+label::before
		content: ''
		display: inline-block
		min-width: 20px
		height: 20px
		border: 1px solid $lightRed
		border-radius: 2px
		background-repeat: no-repeat
		background-position: center center
		background-size: 45% 35%
		margin-right: 15px
		+transition

.checkbox-label
	color: $black
	+transition

	&_error
		color: $lightRed

	&__link
		color: $mainColor
		font-size: 16px
		+transition

		&_error
			color: $lightRed

.checkbox_white
	position: absolute
	z-index: -1
	opacity: 0

	&+label
		cursor: pointer
		display: inline-flex
		align-items: center
		user-select: none

	&+label::before
		content: ''
		display: inline-block
		min-width: 20px
		height: 20px
		border: 1px solid #fff
		border-radius: 2px
		background-repeat: no-repeat
		background-position: center center
		background-size: 45% 35%
		margin-right: 15px

	&:checked+label::before
		background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='0.353553' y1='3.56295' x2='4.43689' y2='7.64628' stroke='white'/%3E%3Cline x1='3.72945' y1='7.64645' x2='10.7295' y2='0.646445' stroke='white'/%3E%3C/svg%3E")

	&_error
		&+label::before
			content: ''
			display: inline-block
			min-width: 20px
			height: 20px
			border: 1px solid $lightRed
			border-radius: 2px
			background-repeat: no-repeat
			background-position: center center
			background-size: 45% 35%
			margin-right: 15px
			+transition

.checkbox_white_error
	&+label::before
		content: ''
		display: inline-block
		min-width: 20px
		height: 20px
		border: 1px solid $lightRed
		border-radius: 2px
		background-repeat: no-repeat
		background-position: center center
		background-size: 45% 35%
		margin-right: 15px
		+transition

.checkbox-label_white
	color: $white
	+transition

	&_error
		color: $lightRed

	&__link
		color: $white
		border-bottom: 1px solid $white
		font-size: 16px
		+transition

		&_error
			color: $lightRed
			border-bottom: 1px solid $lightRed

// auth
.auth
	display: flex
	align-items: center

	&__img
		width: 50px
		height: 50px
		border-radius: 50%
		background-size: cover
		background-repeat: no-repeat
		background-position: top center

	&__name
		padding-left: 25px
		font-size: 18px
		+bold

.directions
	overflow-x: auto
	overflow-y: hidden
	position: relative
	border-bottom: 1px solid $gray

	&::-webkit-scrollbar
		width: 4px
		height: 7px
		background-color: #F0F0F0

	&::-webkit-scrollbar-thumb
		background-color: #E2E2E2
		border-radius: 3px

	&::-webkit-scrollbar-thumb:hover
		background-color: #E2E2E2

	&::-ms-scrollbar
		width: 4px
		height: 8px
		background-color: #F0F0F0

	&::-ms-scrollbar-thumb
		background-color: #E2E2E2
		border-radius: 3px

	&::-ms-scrollbar-thumb:hover
		background-color: #E2E2E2

	&-span
		display: flex
		align-items: center

	&__span
		white-space: nowrap
		user-select: none
		cursor: pointer
		margin-right: 50px
		font-size: 18px
		padding-bottom: 25px
		transition: color .4s ease
		&:hover
			color: $mainColor

		&:last-child
			margin-right: 0

		&_active
			color: $mainColor

	&__line
		position: absolute
		height: 3px
		bottom: 0px
		width: 134px
		left: 0
		z-index: 1
		background-color: $mainColor
		+transition

// arrow
.arrow
	display: flex
	align-items: center
	+transition

	&-prev,&-next
		cursor: pointer
		fill: #999999
		+transition

		&:hover
			fill: #7c7c7c

	&-prev
		margin-right: 25px

// topup
.topup
	cursor: pointer
	z-index: 100
	position: fixed
	bottom: 15px
	left: 15px
	+transition

	&_disabled
		opacity: 0
		z-index: -100

// no-search
.no-search
	&-wrapper
		margin: 100px 0
		+animateOpenPage

	&-text
		color: $mainColor
		font-size: 36px

// success-add-cart
.success-add-cart
	opacity: 0
	z-index: -100
	position: fixed
	right: 25px
	top: 25px
	padding: 15px 30px
	background-color: $white
	border-radius: 5px
	transition: opacity .4s ease, z-index 5s ease
	+boxShadow

	&__title
		font-size: 18px
		color: $mainColor

	&_active
		animation: .4s activeModalWrapper
		opacity: 1
		z-index: 3
		transition: none

.D3Euronism
	display: inline
	font-family: "D3Euronism"
	font-weight: 400
