// footer
.footer
	background-color: #2E2E2E
	color: $white
	padding: 50px 0
	+animateOpenPage

	&-logo__img
		width: 200px

	&-top, &-middle, &-bottom
		display: flex
		justify-content: space-between
		flex-wrap: wrap

	&-bottom-bottom
		margin-top: 50px
		display: flex
		justify-content: space-between
		align-items: center
		
		&__link
			font-size: 16px
			color: rgba(255, 255, 255, 0.6)
			margin-right: 25px

	&-middle
		margin-top: 75px

		&-left
			width: 50%

		&__link
			margin-bottom: 25px
			display: block
			font-size: 18px

			&:last-child
				margin-bottom: 25px

	&-bottom
		margin-top: 75px
		padding-top: 25px
		border-top: 1px solid $gray

		&-left
			line-height: 2em
			width: 70%

		&__link
			display: inline-block
			font-size: 15px
			margin-left: 25px
			color: $white

	&-nav
		align-items: flex-start
		display: flex

		&-block
			margin-left: 50px

		&__link
			display: block
			color: $white
			margin-bottom: 25px

		&__btn
			display: inline-block
			border-radius: 2px
			border: 1px solid $white
			background-color: tranparent
			color: $white
			padding: 20px 40px

	&-social
		display: flex
		align-items: center

		margin-bottom: 25px

		&__link
			margin-right: 25px

	&__adres
		font-size: 18px
		margin-bottom: 25px

	&__link
		color: $white

	&-email-form
		&__title
			font-size: 24px
			margin-bottom: 50px

		&-input
			&__field
				background-color: transparent
				border: none
				border-bottom: 1px solid $white
				width: 100%
				padding: 10px 0
				font-size: 16px
				color: $white

				&::placeholder
					font-size: 16px
					color: $white

			&__field_error
				background-color: transparent
				border: none
				border-bottom: 1px solid $lightRed
				width: 100%
				padding: 10px 0
				font-size: 16px
				color: $lightRed

				&::placeholder
					font-size: 16px
					color: $lightRed

		&-checkbox
			margin-top: 25px

		&__btn
			z-index: 100
			cursor: pointer
			position: absolute
			top: 50%
			right: 0
			transform: translateY(-50%)
			border: none
			padding: 0
			background-color: transparent