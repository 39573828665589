// media - adaptive system
@media screen and (max-width: 1250px)
	// timetable
	.timetable
		&-filters
			flex-direction: column
			align-items: flex-start

		&-checkbox-block
			width: 100%
			margin-top: 50px

	// directions
	.directions
		width: 100%

	// magazine
	.magazine
		&-filters
			width: 100%
			align-items: flex-start
			justify-content: space-between
			display: flex
			padding-bottom: 25px
			margin-bottom: 100px
			border-bottom: 1px solid $gray

			&-block
				padding: 0
				margin: 0
				height: 200px
				overflow-y: auto
				width: 45%
				border: none

				&::-webkit-scrollbar
					width: 4px
					height: 7px
					background-color: #F0F0F0

				&::-webkit-scrollbar-thumb
					background-color: #E2E2E2
					border-radius: 3px

				&::-webkit-scrollbar-thumb:hover
					background-color: #E2E2E2

				&::-ms-scrollbar
					width: 4px
					height: 8px
					background-color: #F0F0F0

				&::-ms-scrollbar-thumb
					background-color: #E2E2E2
					border-radius: 3px

				&::-ms-scrollbar-thumb:hover
					background-color: #E2E2E2

			&-types
				flex-direction: row
				flex-wrap: wrap

		&-catalog
			width: 100%

			&__btn
				width: 100%

		&-bottom
			margin-top: 50px
			flex-direction: column

	// shop
	.shop
		&-filters
			&-block-wrapper
				margin-bottom: 25px
				width: 50%
				border-right: 1px solid $gray

			&-block-wrapper:nth-child(2n+1) &-block
				border-left: 1px solid $gray

@media screen and (max-width: 1150px)
	// header
	.header
		&-menu-hidden
			display: none

		&-menu-burger
			display: block

	// event
	.event
		&-item
			&-img
				width: 400px
				height: 400px

	// shop
	.shop
		&-block
			&-right
				flex-direction: column
				align-items: flex-start
				width: auto

			&-left
				width: auto

			&-price
				margin-bottom: 25px

		&-page
			&-form
				&-block-wrapper
					width: 100%

			&-composition-product
				&-block
					&-wrapper
						flex-direction: column

					&-left, &-right
						width: 100%

					&-left
						border: none
						padding: 0
						margin-bottom: 50px

	// shop-page
	.shop-page
		&-text-form
			&-wrapper
				flex-direction: column

			&-text
				width: 100%
				margin-bottom: 25px

				&__description
					font-size: 18px

			&-form
				width: 100%

	// cart
	.cart
		&-block
			flex-direction: column
			align-items: flex-start

			&-left
				align-items: flex-start
				flex-direction: column
				width: 100%

			&-thumb
				width: 100%

			&__img
				border-radius: 10px
				width: 100%
				height: 300px

			&-text
				margin-top: 50px
				margin-left: 0

			&-right
				width: 100%
				margin-top: 50px
				align-items: center

	// timetable
	.timetable
		&-block-wrapper
			padding: 0

		&-block-container
			width: 50%

		&-block-container:nth-of-type(4n+3) &-block
			padding-left: 0

	// services
	.services
		&-block-wrapper
			flex-direction: column

		&-block
			&_small
				width: 100%

	// about
	.about
		&-block-wrapper
			flex-direction: column
			align-items: center
			justify-content: flex-start

		&-block
			width: 400px
			height: 400px

	// teacher
	.teacher
		&-block-wrapper
			flex-direction: column

		&-block
			width: 100%

			&__title
				font-size: 36px

			&__subtitle
				font-size: 21px

	// email-form
	.email-form
		&-wrapper
			flex-direction: column

		&-left, &-right
			width: 100%

		&-right
			margin-top: 100px

	// library
	.library
		&-block
			&__btn
				& > span
					display: none

@media screen and (max-width: 1100px)
	// shop-page
	.shop-page
		&-main1-image
			&-wrapper
				align-items: flex-start
				flex-direction: column

			&-text
				max-width: 100%

			&-cover
				margin-top: 50px
				width: 100%

		&-main2-image
			&-wrapper
				align-items: flex-start
				flex-direction: column

			&-text
				width: 100%

			&-cover
				margin-top: 50px
				width: 100%

	// feedback
	.feedback
		&-block
			width: 50%

@media screen and (max-width: 1000px)
	// shop
	.shop
		&-block-top
			flex-direction: column
			align-items: center

		&-input, &-filters__btn, &-filters-clear__btn
			width: 100%

		&-filters-clear__btn
			margin-top: 50px

		&-filters__btn
			margin-top: 25px

		&-filters-btn
			width: 100%

		&-block
			width: 100%
			flex-direction: column
			align-items: flex-start

			&-left, &-right
				width: 100%
				flex-direction: column
				align-items: center

			&-right
				margin-top: 75px

			&-thumb
				width: 100%

			&__img
				border-radius: 10px
				width: 100%
				height: 250px

			&__sale
				width: 70px
				height: 70px
				font-size: 24px
				top: -15px
				left: -15px

			&-text
				width: 100%
				margin-top: 50px
				margin-left: 0

			&-price
				width: 100%
				display: flex
				justify-content: space-between
				align-items: center
				margin-bottom: 15px

			&__price
				margin-top: 0

			&__subprice
				font-size: 21px

			&__btn, &-btn
				width: 100%

		&-page
			&-slider-text
				&-arrow
					width: 100%
					display: flex
					justify-content: center

				&-list
					margin-top: 0

					&__li
						width: 100%
						font-size: 21px
						margin-bottom: 25px
						padding-right: 0

				&-circle2
					display: none

			&-composition-product
				&-arrow
					width: 100%
					display: flex
					justify-content: center

				&-list
					&-item
						justify-content: space-between

						&__description
							max-width: 50%

				&-circle2
					display: none

	// timetable-page
	.timetable-page
		&-wrapper
			flex-direction: column

		&-text, &-form
			width: 100%

		&-form
			margin-top: 75px

		&-checkbox
			display: flex
			justify-content: center

	// teacher
	.teacher
		&-modal
			&-content
				margin: 80px 1em
				flex-direction: column

			&-close
				stroke: $white
				top: -60px

			&-text
				padding: 50px
				min-width: 100%

			&-thumb
				display: none

	// cabinet
	.cabinet
		&-block
			&-setting
				width: 100%

			&__btn
				width: 100%

	// post
	.post
		margin: 50px 0
		&-cover
			flex-direction: column
			align-items: flex-start

			&-text
				width: 100%
				padding: 0

			&-thumb
				margin-top: 50px
				width: 100%
				height: 300px

@media screen and (max-width: 920px)
	// library
	.library
		&-block
			&-left
				width: 100%
				align-items: flex-start
				flex-direction: column

			&-text
				max-width: 100%
				padding-top: 50px
				padding-left: 0

			&-thumb
				border-radius: 10px
				width: 100%
				height: 200px

			&__title
				font-size: 24px

			&__btn
				display: none

@media screen and (max-width: 900px)
	// header
	.header
		&-modal
			&-nav
				padding-top: 100px

			&-content
				justify-content: flex-start

		&-wrapper-top
			display: none

	// main
	.services
		&-block
			&:last-child
				margin-bottom: 0

	// shop
	.shop
		&-page
			&-awo-form-block-wrapper
				width: 100%

			&-form-block-wrapper
				width: 100%
				flex-direction: column
			&-form-input-wrapper
				margin-bottom: 15px
			&-form-input-wrapper, &-form__btn
				width: 100%

			&-main1
				&-form
					width: 100%

			&-main1-image
				&-form
					width: 100%

				&-cover
					display: none

			&-main2-image
				&-cover
					display: none

			&-slider-text
				&-tabs
					&-item
						padding-right: 25px

			&-composition-product
				&-modules
					&-description
						margin: 15px 0

					&__description
						font-size: 18px

					&-items-wrapper
						flex-direction: column
						align-items: flex-start

					&-item
						padding: 0
						margin-bottom: 25px

						&:last-child
							margin-bottom: 0

				&-block
					flex-direction: column

					&-text
						&__title
							font-size: 24px

						&__description
							font-size: 18px

					&-form
						width: 100%
						margin-top: 25px

			&-comparison
				&-blocks
					flex-direction: column

				&-block
					width: 100%
					margin-bottom: 15px

			&-content
				&-wrapper
					flex-direction: column
					align-items: flex-start

				&-text
					max-width: 100%
					margin-bottom: 25px

				&-cover
					width: 100%

			&-video
				&-block
					& iframe
						height: 400px

			&-demo
				&-wrapper
					flex-direction: column
					align-items: flex-start

				&-text
					width: 100%
					margin-bottom: 25px

					&-form
						margin-top: 25px
						width: 100%

				&-cover
					width: 100%

			&-fixed-form
				&-wrapper
					width: 100%

	// timetable
	.timetable
		margin-top: 100px

	// feedback
	.feedback
		&-modal
			&-content
				padding: 25px
				margin: 25px 0
				width: 95%

				&-text
					width: 100%
					&__course
						font-size: 18px

					&__description
						font-size: 21px

				&-video
					width: 100%

	// footer
	.footer
		&-top
			flex-direction: column

		&-middle
			&-left
				width: 100%

		&-bottom-bottom
			flex-direction: column
			align-items: flex-start
			justify-content: flex-start

			& > .nagibinstudio
				margin-top: 50px

		&-nav
			margin-top: 50px

			flex-direction: column

			&-block
				margin-top: 50px
				margin-left: 0

		&-email-form
			display: none

	// reglog
	.reglog
		&-circle
			display: none

	// privacy
	.privacy
		&-text
			max-width: 100%

			&-block
				&__ul
					margin-left: 15px

@media screen and (max-width: 820px)
	// header
	.header
		&__btn, &-right
			display: none

		&-cart-mobile
			display: block

	// main
	.main
		&__title
			font-size: 56px
			max-width: 100%

	// categories
	.categories
		&-item-wrapper
			padding: 0
			width: 100%

		&-thumb
			display: none

	// event
	.event
		&-item
			display: flex
			flex-direction: column

			&-text
				margin-right: 0
				max-width: 100%

			&__title
				margin-top: 25px

			&__description
				margin-top: 25px

			&-thumb
				display: none

	// teacher
	.teacher
		&-block
			&__title
				font-size: 24px

			&__subtitle
				font-size: 18px

	// magazine
	.magazine
		&-block-wrapper
			flex-direction: column

		&-block
			width: 100%

			&-types
				flex-direction: column
				justify-content: flex-start
				align-items: flex-start
				padding: 25px 15px

			&-types-left
				margin-bottom: 10px

	// timetable
	.timetable
		&-block
			pointer-events: none
			&:hover &-auth
				opacity: 1

			&-btn
				pointer-events: auto
				margin-top: 50px
				position: static
				opacity: 1

			&__btn
				width: 100%

@media screen and (max-width: 800px)
	// shop
	.shop
		&-page
			&-main1, &-main2
				margin: 50px 0

				&__title
					font-size: 42px

			&-main1-image
				margin: 50px 0

				&-text
					&__title
						font-size: 42px

			&-main2-image
				margin: 50px 0

				&-text
					&__title
						font-size: 42px

			&-composition-product
				&-list
					&-item
						flex-direction: column
						align-items: flex-start

						&__title
							margin-right: 0
							margin-bottom: 25px

						&__description
							max-width: 100%

@media screen and (max-width: 750px)
	// cabinet
	.cabinet
		&-block-info
			flex-direction: column

			&-img
				width: 150px
				height: 150px

			&-text
				text-align: center
				padding-left: 0
				padding-top: 50px

			&__title
				max-width: 100%

@media screen and (max-width: 700px)
	// feedback
	.feedback
		&-top
			align-items: flex-start
			flex-direction: column

			&__btn
				margin-top: 50px
				width: 100%

		&-filters
			flex-direction: column
			padding-bottom: 25px

			&-block-wrapper
				margin-bottom: 25px
				width: 100%

				border-right: 1px solid $gray
				border-left: 1px solid $gray

		&-block
			width: 100%

	// footer
	.footer
		&-bottom-bottom
			display: flex
			flex-direction: column

			&__link
				display: block
				margin-right: 0
				margin-bottom: 15px

@media screen and (max-width: 640px)
	// shop
	.shop
		margin: 100px 0
		&-filters
			flex-direction: column

			&-block-wrapper
				width: 100%
				border-left: 1px solid $gray
				border-right: 1px solid $gray

			&-block-wrapper:nth-child(2n+1) &-block
				border: none

		&-block
			&:first-child
				border-top: none

		&-page
			&-composition-product
				&__btn
					width: 100%

	// cart
	.cart
		&-block-bottom
			padding: 50px 0
			flex-direction: column
			align-items: flex-start

		&__btn
			width: 100%
			margin-top: 25px

	// timetable
	.timetable
		&-block-wrapper
			padding: 0

		&-block-container
			width: 100%

		&-block
			padding: 0
			border-right: none

	// teacher
	.teacher
		&-block
			flex-direction: column
			align-items: flex-start
			height: 500px
			&::before
				width: 500px
				height: 500px
				left: 50%
				transform: translateX(-50%)
				right: 0

			&:hover::before
				width: 500px
				height: 500px

			&-left-top
				display: none

			&-text
				margin: 50px

			&__title
				font-size: 28px

			&__subtitle
				font-size: 24px

			&-img
				margin: auto
				width: 300px
				height: 100%

	// next-post
	.next-post
		padding: 50px 0
		&:hover &-media
			transform: scale(1)

		&-wrapper
			text-align: center
			display: flex
			justify-content: center

		&-media
			align-items: center
			flex-direction: column

		&-arrow
			margin-top: 50px
			& > svg
				width: 80px

	// post
	.post
		&-block
			&__title
				font-size: 28px

	// footer
	.footer
		&-bottom-right
			align-items: flex-start
			display: flex
			flex-direction: column

		&-bottom
			align-items: flex-start
			&__link
				margin-left: 0
				&:first-child
					margin-bottom: 25px

	// error
	.error
		margin: 100px 0
		&__title
			font-size: 48px

@media screen and (max-width: 600px)
	// feedback
	.feedback
		&-modal
			&-content
				&-text
					&__name
						font-size: 24px

					&__description
						font-size: 18px

	// shop
	.shop
		&-block
			&-btn
				&-annotation
					width: 300px

		&-page
			&-main1, &-main2
				&__title
					font-size: 36px

				&__description
					font-size: 21px

				&__btn
					width: 100%

			&-main1-image
				&-text
					&__title
						font-size: 36px

					&__description
						font-size: 21px

					&__btn
						width: 100%

			&-main2
				&-date
					flex-direction: column

					&-right
						margin-top: 15px

			&-main2-image
				&-text
					&__title
						font-size: 36px

					&__description
						font-size: 21px

					&__btn
						width: 100%

			&-slider-text
				&-tabs
					&-item
						&__title
							font-size: 32px

				&-list
					&__li
						font-size: 18px
						padding-bottom: 50px

		&-link
			&-block
				text-align: center
				padding: 25px

				&__title
					font-size: 24px

				&-item
					font-size: 18px

		&-error
			&-block
				text-align: center
				padding: 25px

				&__title
					font-size: 24px

				&-item
					font-size: 18px

@media screen and (max-width: 580px)
	// magazine
	.magazine
		&-filters
			flex-direction: column
			border-bottom: none
			margin-bottom: 50px

			&-block
				padding: 25px 0
				width: 100%
				height: auto
				border-bottom: 1px solid $gray

		&-bottom
			margin-top: 25px

@media screen and (max-width: 540px)
	// interface
	.title
		font-size: 36px
		margin-bottom: 50px

	// header
	.header
		&-modal
			&-content
				padding-left: 25px
				padding-top: 50px
				padding-right: 50px

			&-logo__img
				width: 200px

			&__link
				font-size: 36px

	// services
	.services
		&-circle1, &-circle2
			width: 400px
			height: 400px

		&-block
			padding: 25px
			&__title
				font-size: 24px

			&__description
				font-size: 18px

			&__number
				left: 5px
				top: 5px

	// teacher
	.teacher
		&-modal
			&-text
				padding: 25px

			&-thumb
				margin-top: 25px

			&__img
				width: 300px

	// email-form
	.email-form
		&__title
			font-size: 32px

		&__subtitle
			margin-top: 25px
			font-size: 18px

	// cart
	.cart
		margin: 50px 0

		&-null
			&__title
				font-size: 36px

	// feedback
	.feedback
		&-modal
			&-close
				right: 5px
				top: 5px

	// footer
	.footer
		&-wrapper
			flex-direction: column

		&-bottom
			margin-top: 25px

	// success-add-cart
	.success-add-cart
		text-align: center
		max-width: 250px

	//timetable page
	.timetable
		&-page
			&__title
				font-size: 32px

@media screen and (max-width: 500px)
	// interface
	.checkbox
		&+label
			font-size: 13px

		&+label > a
			font-size: 13px

	.checkbox_error
		&+label
			font-size: 13px

		&+label > a
			font-size: 13px

	// shop
	.shop
		&-page
			&-main1, &-main2
				&__title
					font-size: 28px
			&-main1-image
				&-text
					&__title
						font-size: 28px
				&-cover
					width: 100%
			&-main2-image
				&-text
					&__title
						font-size: 28px
				&-cover
					width: 100%
			&-services
				margin-top: 75px
			&-slider-text
				&-tabs
					&-item
						&__title
							font-size: 24px
			&-composition-product
				&-modules
					&-item
						&__title
							font-size: 24px
				&-list
					&-item
						&__title
							font-size: 24px

			&-faq
				margin: 50px 0

				&__title
					font-size: 32px

				&-item
					&-top
						&__title
							font-size: 24px

					&-bottom
						&__description
							font-size: 18px

			&-comparison
				margin: 50px 0

				&__title
					font-size: 32px

				&-block
					&__title
						font-size: 24px

					&-list
						&__item
							font-size: 16px

			&-content
				margin: 50px 0

				&-text
					&__title
						font-size: 32px

					&__description
						font-size: 18px

			&-video
				margin: 50px 0

				&__title
					font-size: 32px

				&-block
					& iframe
						height: 300px

			&-demo
				margin: 50px 0

				&-text
					&__title
						font-size: 32px

					&__description
						font-size: 18px

			&-programm
				margin: 50px 0

				&__title
					font-size: 32px

				&__description
					margin-bottom: 25px

				&-item
					&-top
						&-text
							&__title
								font-size: 24px

					&-bottom
						&__description
							font-size: 18px

@media screen and (max-width: 480px)
	//header
	.header
		&-modal
			&-close
				left: 30px
				top: 45px

				& > svg
					width: 35px
					height: 35px

			&-logo__img
				display: none

	// post
	.post
		&-cover
			&-block-top
				align-items: flex-start
				flex-direction: column

			&__date
				padding-top: 25px
				padding-left: 0
				color: $black

	// reglog
	.reglog
		&__title
			font-size: 28px

	// shop
	.shop
		&-block
			&__price
				font-size: 28px

	// post
	.post
		&-block
			&__description
				font-size: 20px

				& > p
					font-size: 20px

@media screen and (max-width: 460px)
	// interface
	.title
		font-size: 32px

	// main
	.main
		&__title
			font-size: 48px

	// categories
	.categories
		&-item
			&__title
				font-size: 28px

	// event
	.event
		&-item
			&__title
				font-size: 30px

			&-date
				margin-top: 30px
				display: flex
				flex-direction: column

			&__date-range
				margin-top: 15px

	// timetable
	.timetable
		&-modal
			&-text
				width: 230px

	// footer
	.footer
		&-bottom
			flex-direction: column

			&-right
				margin-top: 25px

@media screen and (max-width: 440px)
	// event
	.event
		&-item
			&__type
				&_gray, &_color
					font-size: 16px

	// post
	.post
		&-cover
			&__type
				&_gray, &_color
					font-size: 16px

			&__title
				font-size: 28px

			&__description
				font-size: 20px

		&-block
			margin: 50px 0
			&__title
				font-size: 24px

@media screen and (max-width: 420px)
	// interface
	.checkbox-label_white
		font-size: 12px

	// header
	.header
		&-logo__img
			width: 180px

	// main
	.main
		margin-top: 70px

		&__description
			font-size: 22px

	// categories
	.categories
		&-item
			&__title
				font-size: 25px

			&__subtitle
				font-size: 22px

	// event
	.event
		margin-top: 100px

	// about
	.about
		&-block
			width: 350px
			height: 350px

			&__title
				font-size: 54px

			&__subtitle
				font-size: 18px

	// shop
	.shop
		&-block
			&__title
				font-size: 24px

			&__price
				font-size: 22px

	// teacher
	.teacher
		&-block
			&-text
				margin: 25px

		&-modal
			&__description
				font-size: 16px

			&__img
				width: 250px

			&__btn
				margin-top: 25px

	// email-form
	.email-form
		&-wrapper
			padding: 50px 25px

		&__title
			font-size: 24px

		&__subtitle
			margin-top: 25px
			font-size: 18px

	// magazine
	.magazine
		&-block
			&_big-types
				flex-direction: column
				align-items: flex-start

			&_big-types-left
				margin-bottom: 10px

	//timetable page
	.timetable
		&-page
			&__title
				font-size: 26px

			&__description
				font-size: 21px

			&__checkbox + label
				white-space: normal
				font-size: 14px

@media screen and (max-width: 390px)
	// interface
	.title
		font-size: 28px

	// header
	.header
		&-modal
			&__link
				font-size: 24px

	// main
	.main
		&__title
			font-size: 40px

	// event
	.event
		&-item
			&__title
				font-size: 28px

			&__description
				font-size: 21px

			&__date
				font-size: 18px

	// about
	.about
		&-block
			width: 280px
			height: 280px
