// cabinet
.cabinet
	margin: 100px 0
	+animateOpenPage

	&-error-wrapper
		cursor: pointer
		display: flex
		justify-content: space-between
		align-items: center
		margin-bottom: 25px
		border-radius: 5px
		padding: 25px
		animation: ErrorCabinet .4s
		+boxShadow

	&-error
		color: $red
		font-size: 21px

	&-input
		position: relative
		margin-bottom: 35px

		&__input
			outline: none
			font-size: 16px
			padding: 15px 0
			display: block
			width: 100%
			border: none
			border-bottom: 1px solid $gray

		&__label
			color: $gray
			font-size: 16px
			position: absolute
			pointer-events: none
			top: 15px
			+transition

		&__input
			&:focus ~ label,
			&:valid ~ label
				top: -15px
				font-size: 14px
				color: $mainColor

		&__span
			position: relative
			display: block
			width: 100%
			&:before,
			&:after
				content: ''
				height: 2px
				width: 0
				bottom: 0
				position: absolute
				background-color: $mainColor
				+transition

			&:before
				left: 50%

			&:after
				right: 50%

			&__error
				height: 2px
				width: 100%
				bottom: 0
				position: absolute
				background-color: $lightRed
				+transition

		&__input:focus
			~ .cabinet-input__span:before,
			~ .cabinet-input__span:after
				width: 50%

		&-eye
			position: absolute
			right: 0
			top: 50%
			transform: translateY(-45%)
			z-index: 2
			user-select: none
			cursor: pointer
			width: 20px
			height: 20px

		&__label__error
			position: relative
			display: block
			margin-top: 15px
			color: $lightRed
			font-size: 15px
			animation: inputLabelTopCabinet .2s

	&-select
		margin-bottom: 35px

		&__label
			font-size: 16px
			margin-bottom: 5px
			display: block
			color: $mainColor

		&__select
			outline: none
			font-size: 16px
			padding: 10px 0
			display: block
			width: 100%
			border: none
			border-bottom: 1px solid $gray

		&__option
			margin: 15px

		&__span
			position: relative
			display: block
			width: 100%
			&:before,
			&:after
				content: ''
				height: 2px
				width: 0
				bottom: 0
				position: absolute
				background-color: $mainColor
				+transition

			&:before
				left: 50%

			&:after
				right: 50%

		&__select:focus
			~ .cabinet-select__span:before,
			~ .cabinet-select__span:after
				width: 50%

	&__title
		margin-bottom: 50px

	&-block-info
		display: flex
		align-items: center
		padding: 50px
		border-radius: 5px
		margin-bottom: 25px
		+boxShadow

		&-thumb
			cursor: pointer
			position: relative
			border-radius: 50%
			overflow: hidden

		&-img
			width: 120px
			height: 120px
			background-size: cover
			background-repeat: no-repeat
			background-position: center center

		&-svg
			width: 100%
			height: 100%
			border-radius: 50%
			display: flex
			justify-content: center
			align-items: center
			background-color: rgba(0, 0, 0, 0.6)
			position: absolute
			top: 50%
			left: 50%
			transform: translate(-50%,-50%)
			opacity: 0
			+transition
			&:hover
				opacity: 1

		&-text
			padding-left: 50px

		&__title
			max-width: 400px
			font-size: 24px

		&__subtitle
			margin-top: 15px
			font-size: 18px

			& > span
				color: $gray

	&-block
		padding: 35px
		border-radius: 5px
		margin-bottom: 25px
		+boxShadow

		&__title
			margin-bottom: 50px
			font-size: 24px

		&-setting
			width: 50%

		&__btn
			width: 50%
			margin-top: 15px

@keyframes inputLabelTopCabinet
	0%
		margin-top: -15px
		opacity: 0

	100%
		margin-top: 15px
		opacity: 1

@keyframes ErrorCabinet
	0%
		opacity: 0

	100%
		opacity: 1
