// mainColor 
$mainColor: #3A38DC
$lightMainColor: #F3F3FD

// standart
$white: #ffffff
$black: #000000
$gray: #cccccc
$lightGray: #eeeeee
$red: #FD291E
$lightRed: #FD5754