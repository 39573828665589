// reglog
.reglog
	margin: 100px 0
	+animateOpenPage

	&-circle
		position: absolute
		left: 300px
		width: 400px
		height: 400px

	&-wrapper
		display: flex
		align-items: center
		justify-content: center

	&-form
		display: flex
		flex-direction: column
		align-items: center
		width: 400px

		&__error
			margin-bottom: 25px
			font-size: 21px
			color: $lightRed
			text-align: center

	&__title
		text-align: center
		font-size: 36px
		margin-bottom: 25px

	&__link
		font-size: 24px
		color: $mainColor
		margin-bottom: 50px
		text-decoration: underline

		&_gray
			text-decoration: underline
			font-size: 18px
			color: $gray
			margin-top: 50px

	&-input, &__btn
		width: 100%
		margin-bottom: 15px
