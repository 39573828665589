// privacy
.privacy
	margin: 100px 0
	+animateOpenPage

	&-text
		margin-top: 100px
		max-width: 75%

		&-block
			margin-bottom: 100px

			&:last-child
				margin-bottom: 0

			&__title
				font-size: 28px
				margin-bottom: 50px

			&__description
				font-size: 21px
				margin-bottom: 25px

			&__iframe
				width: 100%
				height: 700px

			&__ul
				margin-bottom: 50px
				margin-left: 50px

			&__li
				line-height: 1.4em
				margin-bottom: 25px
				font-size: 21px
