// teacher
.teacher
	margin-top: 100px
	+animateOpenPage

	&-block-wrapper
		display: flex
		justify-content: space-between
		flex-wrap: wrap

	&-modal
		&-wrapper
			display: flex
			justify-content: center
			align-items: flex-start

			z-index: -1000
			opacity: 0
			width: 100%
			height: 100%
			position: fixed
			top: 0
			left: 0
			background-color: rgba(0,0,0, 0.4)
			overflow-y: auto
			transition: opacity .4s ease, z-index 1.1s ease

			&_active
				z-index: 1000
				opacity: 1
				transition: none
				+activeModalWrapper

		&-content
			position: relative
			display: inline-flex
			justify-content: center
			align-items: center
			margin: 50px 1em

			border-radius: 2px

			min-height: 60%
			height: auto

			min-width: 40%
			max-width: 90%

			background-color: $white

			&_active
				+activeModalContent

		&-close
			cursor: pointer
			position: absolute
			right: 30px
			top: 30px
			stroke: $black

		&-text
			display: flex
			flex-direction: column
			align-items: flex-start
			justify-content: center
			padding-left: 50px
			padding-top: 50px
			padding-bottom: 50px
			max-width: 60%

		&__title
			font-size: 36px

		&__subtitle
			color: $gray
			margin-top: 15px
			font-size: 24px

		&__description
			max-width: 100%
			margin-top: 25px
			font-size: 24px

			& > h1,h2,h3,h4,h5,h6
				margin-bottom: 25px

			& > p
				font-size: 24px
				margin-bottom: 25px

			& > ul > li
				display: block
				position: relative
				font-size: 24px
				padding: 10px 25px
				list-style: none
				&::before
					content: ''
					position: absolute
					width: 7px
					height: 7px
					border-radius: 50%
					top: 50%
					transform: translateY(-50%)
					left: 0px
					background-color: $mainColor

		&__btn
			margin-top: 75px

		&-thumb
			min-height: 100%
			display: flex
			flex-direction: column
			justify-content: center

		&__img
			user-select: none
			width: 450px
			border-radius: 35px 0 0 35px

	&-block
		cursor: pointer
		overflow: hidden
		position: relative
		display: flex
		justify-content: space-between
		align-items: flex-end
		width: 49%
		height: 300px
		border: 1px solid $gray
		border-radius: 2px
		margin-bottom: 25px
		&::before
			content: ''
			position: absolute
			top: 50%
			right: -100px
			transform: translateY(-50%)
			height: 400px
			width: 400px
			background-color: $lightMainColor
			z-index: -1
			border-radius: 50%
			+transition

		&:hover &-left-top
			width: 210px
			height: 210px

		&:hover::before
			width: 450px
			height: 450px
			
		&.big
			width: 100%
			height: 500px

		&.big &-text &__title
			font-size: 36px

		&.big &-text &__subtitle
			font-size: 24px

		&-img
			position: relative
			z-index: 2
			height: 100%
			width: 50%
			background-size: cover
			background-repeat: no-repeat
			background-position: top center

		&-text
			margin-bottom: 30px
			margin-left: 30px

		&__title
			font-size: 24px

		&__subtitle
			font-size: 18px
			margin-top: 15px

		&-left-top
			z-index: -1
			position: absolute
			width: 200px
			height: 200px
			border-radius: 50%
			top: -50px
			left: -50px
			border: 20px solid $lightMainColor
			+transition

	&-btn-wrapper
		margin-top: 50px
		margin-bottom: 100px
		display: flex
		justify-content: center
