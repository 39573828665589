// library
.library
	margin: 100px 0
	+animateOpenPage
	
	&-block-top
		display: flex
		position: relative

	&__title
		margin-bottom: 50px

	&__subtitle
		padding-top: 5px
		padding-left: 10px
		font-size: 24px
		color: $gray
		+light

	&-block
		display: flex
		justify-content: space-between
		align-items: center
		padding: 30px
		border-radius: 5px
		margin-bottom: 25px
		+boxShadow
		&:hover &__btn
			color: #969696
			fill: #969696

		&-left
			display: flex
			align-items: center

		&-thumb
			width: 120px
			height: 120px
			border-radius: 50%
			background-size: cover
			background-repeat: no-repeat
			background-position: top center

		&-text
			max-width: 600px
			padding-left: 50px

		&__subtitle
			display: block
			font-size: 18px
			margin-bottom: 15px
			color: $gray

		&__title
			font-size: 28px

		&__btn
			color: $gray
			fill: $gray
			display: flex
			align-items: center
			+transition

			& > span
				display: block
				margin-left: 15px

	&-block-plus
		border-radius: 2px
		display: flex
		justify-content: center
		margin-top: 50px
		stroke: $gray
		+transition
		&:hover
			stroke: #969696

	&-null
		text-align: center
		max-width: 750px
		margin: 75px auto 25px auto

		&__title
			font-size: 24px
			color: $gray
