// timetable
.timetable
	margin-top: 100px
	+animateOpenPage

	&__title
		margin-bottom: 75px

	&-catalog
		display: flex
		flex-wrap: wrap

	&-block-wrapper
		display: flex
		flex-wrap: wrap

	&-filters
		display: flex
		justify-content: space-between
		align-items: flex-end
		flex-wrap: wrap

	&-checkbox-block
		display: flex
		padding: 25px 0
		overflow-x: auto
		overflow-y: hidden
		position: relative
		border-bottom: 1px solid $gray

		&::-webkit-scrollbar
			width: 4px
			height: 7px
			background-color: #F0F0F0

		&::-webkit-scrollbar-thumb
			background-color: #E2E2E2
			border-radius: 3px

		&::-webkit-scrollbar-thumb:hover
			background-color: #E2E2E2

		&::-ms-scrollbar
			width: 4px
			height: 8px
			background-color: #F0F0F0

		&::-ms-scrollbar-thumb
			background-color: #E2E2E2
			border-radius: 3px

		&::-ms-scrollbar-thumb:hover
			background-color: #E2E2E2

	&-checkbox
		margin-right: 25px
		white-space: nowrap
		&:last-child
			margin-right: 0

	&-block-container
		width: 25%
		padding: 50px 0
		border-bottom: 1px solid $gray
		+transition
		&:nth-of-type(-n+4)
			border-top: 1px solid $gray

	&-block-container-page:nth-of-type(-n+4)
		border-top: none

	&-block-container:nth-of-type(4n+1) &-block
		padding-left: 0

	&-block
		cursor: pointer
		height: 100%
		display: flex
		flex-direction: column
		justify-content: space-between
		padding: 0 25px
		border-right: 1px solid $gray

		&:hover &__title
			color: $mainColor

		&:hover &-btn
			opacity: 1

		&:hover &-auth
			opacity: 0

		&-bottom
			position: relative

		&__type
			&_gray
				display: inline-block
				border-radius: 100px
				background-color: $lightGray
				color: $black
				font-size: 16px
				padding: 5px 15px
				text-align: center

			&_color
				margin-right: 5px
				display: inline-block
				border-radius: 100px
				background-color: $mainColor
				color: $white
				font-size: 16px
				padding: 5px 15px
				text-align: center

		&__title
			font-size: 24px
			margin-top: 25px
			+transition

		&-date
			margin-top: 25px
			display: flex
			align-items: center

		&__date
			padding-left: 15px

		&-auth
			margin-top: 5px
			+transition

			&:first-child
				margin-top: 50px

		&-btn
			opacity: 0
			position: absolute
			bottom: 0
			left: 0
			+transition

		&__btn
			padding: 15px 40px

	&-btn-wrapper
		margin-top: 50px
		margin-bottom: 100px
		display: flex
		justify-content: center

	&-bottom
		display: flex
		justify-content: center
		margin-top: 25px

// timetable-page
.timetable-page
	margin-top: 100px

	&-wrapper
		display: flex
		justify-content: space-between
		align-items: center

	&-text
		width: 50%

	&-type
		margin-bottom: 25px

	&__type
		&_gray
			text-align: center
			display: inline-block
			border-radius: 100px
			background-color: $lightGray
			color: $black
			font-size: 18px
			padding: 5px 15px
			text-align: center

		&_color
			text-align: center
			margin-right: 5px
			display: inline-block
			border-radius: 100px
			background-color: $mainColor
			color: $white
			font-size: 18px
			padding: 5px 15px
			margin-bottom: 15px
			text-align: center

	&__title
		font-size: 42px

	&__description
		margin-top: 25px
		font-size: 24px

	&-auth-wrapper
		margin-top: 45px

	&-auth
		margin-top: 5px

	&-form
		text-align: center
		width: 40%

		&__title
			margin-bottom: 35px
			font-size: 36px

		&-link
			margin-top: 50px

		&__link
			margin: 0 5px

		&__btn
			margin-top: 15px
			width: 100%

		&__success
			height: 100%
			font-size: 32px
			color: $mainColor

	&-checkbox
		margin-top: 25px
